import { DatePicker, Form, Input, Select } from 'antd'

import { MustBeRequired } from 'components/rules/rules'
import { renderFormatMessage } from 'helpers/intl'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { ServiceStatusDescription, ServiceStatusReasons } from 'models/listing'
import moment from 'moment'
import { theme } from 'theme'
import { ServiceStatusSectionHeader } from './service-status-section-header'
import { MultiOptionSelectorWrapper } from 'atom/select'
import { DropdownOption } from 'models/option'
import { listingServiceStatus } from 'models/connector'

interface props {
  onServiceStatusChange: (event: string) => void
  isDisableStatus: (status: string) => boolean
  loading: boolean
  newStatus: string
  displayStatusDetail: (newStatus: string) => boolean
  //For keeping previous implementation
  showWarning: boolean
  currentReasons: ServiceStatusReasons[]
  serviceDescriptions: ServiceStatusDescription[]
  status_changed_since?: string | moment.Moment
  selectRef: React.MutableRefObject<any>
  handleSelect: (value: string) => void
  onReasonChange: (value: string) => void
  dependentSelectorValue: any
  secondDropdownOptions: DropdownOption[]
  showOtherReasonField: boolean
  isSameTenant: boolean
  isSameStatus: boolean
  shouldDisplaySection: (status: string) => boolean
  hasSpecificServiceStatuses: boolean
  initialActivationWarning: (newStatus: string) => boolean
}

export const StatusChangeSection: React.FC<props> = ({
  onServiceStatusChange,
  isDisableStatus,
  loading,
  newStatus,
  displayStatusDetail,
  showWarning,
  currentReasons,
  serviceDescriptions,
  status_changed_since,
  selectRef,
  handleSelect,
  onReasonChange,
  dependentSelectorValue,
  secondDropdownOptions,
  showOtherReasonField,
  isSameStatus,
  isSameTenant,
  shouldDisplaySection,
  hasSpecificServiceStatuses,
  initialActivationWarning,
}) => {
  const { selectText } = useGeneralTranslation()

  const { serviceStatusText } = useSelectorTranslation()
  const {
    statusIsRequired,
    characterMaxLimitText,
    statusChangeText,
    warningText,
    newStatusSinceText,
    notesText,
  } = useListingBasicTrans()

  const {
    startTypingText,
    selectAReasonText,
    selectReasonForStatusChangeText,
    specificANewReasonText,
    typeAReasonText,
  } = useSelectorTranslation()

  const title = statusChangeText

  return (
    <ServiceStatusSectionHeader title={title} hide={!displayStatusDetail(newStatus)}>
      <>
        {!isSameStatus && !isSameTenant && (
          <p className="paragraph-04-regular" style={{ color: theme.colors.darkGray }}>
            {warningText}
          </p>
        )}
        <Form.Item
          name="new_status"
          label={serviceStatusText}
          rules={[MustBeRequired(statusIsRequired)]}
          className={`service-status ${
            displayStatusDetail(newStatus) && newStatus === 'active' ? 'service-status-help' : ''
          }`}
          // ! remove for now until further discussion
          // help={displayStatusDetail(newStatus) && newStatus === 'active' && helpText}
          help={
            hasSpecificServiceStatuses && initialActivationWarning(newStatus)
              ? renderFormatMessage('dashboard.text.initialActivationWarning', 'Initial activation warning')
              : null
          }
        >
          <Select
            placeholder={`${selectText} ${serviceStatusText}`}
            onChange={onServiceStatusChange}
            disabled={loading}
            loading={loading}
          >
            {listingServiceStatus.map((status, index) => (
              <Select.Option
                disabled={isDisableStatus(status)}
                key={`${status}-${index}`}
                value={status}
                label={status}
              >
                {renderFormatMessage(`dashboard.serviceStatus.${status}`, status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <br />
        <>
          {!shouldDisplaySection('under_repair') && !shouldDisplaySection('active') ? (
            <Form.Item name="reason" label={selectAReasonText}>
              <MultiOptionSelectorWrapper removeSVG={true} longerWidth={true}>
                <Select
                  ref={selectRef}
                  onSelect={handleSelect}
                  allowClear={true}
                  placeholder={selectReasonForStatusChangeText}
                  mode="multiple"
                  onChange={onReasonChange}
                  maxTagCount={1}
                  value={dependentSelectorValue}
                  notFoundContent={startTypingText}
                  disabled={loading}
                  loading={loading}
                >
                  {secondDropdownOptions.map((option) => (
                    <Select.Option value={option.key}>{option.label}</Select.Option>
                  ))}
                </Select>
              </MultiOptionSelectorWrapper>
            </Form.Item>
          ) : null}
        </>

        {showOtherReasonField && (
          <Form.Item name="other_reason" rules={[{ required: true }]} label={typeAReasonText}>
            <Input style={{ height: '38px' }} placeholder={specificANewReasonText} type="text" />
          </Form.Item>
        )}
        {displayStatusDetail(newStatus) && (
          <>
            <Form.Item name="status_changed_since" label={newStatusSinceText} className="service-status">
              <DatePicker
                format="YYYY/MM/DD hh:mm A"
                dropdownClassName={'inside-modal time-date-picker'}
                disabledDate={(current) => current < moment(status_changed_since)}
                showTime={{ use12Hours: true }}
                defaultValue={status_changed_since ? moment(status_changed_since) : moment()}
              />
            </Form.Item>
            <Form.Item
              name="status_tracking_notes"
              rules={[{ required: false, max: 255, message: characterMaxLimitText }]}
              label={notesText}
              className="service-status"
            >
              <Input.TextArea placeholder={notesText} />
            </Form.Item>
          </>
        )}
      </>
    </ServiceStatusSectionHeader>
  )
}
