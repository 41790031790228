// chargersReducer.ts

import { ChargersPerTenant } from 'models/charger'
import { SwtchError } from 'models/error'
import { ChargersPerTenantFilter } from 'models/filter'
import { NewListing } from 'models/listing'
import { PaginationMeta } from 'models/pagination'

export type ChargersState = {
  loading: boolean
  error: SwtchError | undefined
  chargersPerTenant: ChargersPerTenant[]
  pagination: PaginationMeta
  vendors: string[]
  selectedRowKeys: number[]
  selectedListings: NewListing[]
  showChargerModal: boolean
  showListingModal: boolean
  showSearchQuery: boolean
  filters: ChargersPerTenantFilter
}

export const initialState: ChargersState = {
  loading: false,
  error: undefined,
  chargersPerTenant: [],
  pagination: { currentPage: 1, totalEntries: 31, perPage: 20 },
  vendors: [],
  selectedRowKeys: [],
  selectedListings: [],
  showChargerModal: false,
  showListingModal: false,
  showSearchQuery: false,
  filters: {
    page: 1,
    allListings: false,
    serviceStatuses: [],
    ocppStatuses: [],
    vendors: [],
    connectivityStatus: undefined,
  },
}

export type ChargersAction =
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: SwtchError | undefined }
  | { type: 'SET_CHARGERS'; payload: ChargersPerTenant[] }
  | { type: 'SET_PAGINATION'; payload: PaginationMeta }
  | { type: 'SET_VENDORS'; payload: string[] }
  | { type: 'SET_SELECTED_ROW_KEYS'; payload: number[] }
  | { type: 'SET_SELECTED_LISTINGS'; payload: NewListing[] }
  | { type: 'SET_SHOW_CHARGER_MODAL'; payload: boolean }
  | { type: 'SET_SHOW_LISTING_MODAL'; payload: boolean }
  | { type: 'SET_SHOW_SEARCH_QUERY'; payload: boolean }
  | { type: 'SET_FILTERS'; payload: ChargersPerTenantFilter }

export const chargersReducer = (state: ChargersState, action: ChargersAction): ChargersState => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_ERROR':
      return { ...state, error: action.payload }
    case 'SET_CHARGERS':
      return { ...state, chargersPerTenant: action.payload }
    case 'SET_PAGINATION':
      return { ...state, pagination: action.payload }
    case 'SET_VENDORS':
      return { ...state, vendors: action.payload }
    case 'SET_SELECTED_ROW_KEYS':
      return { ...state, selectedRowKeys: action.payload }
    case 'SET_SELECTED_LISTINGS':
      return { ...state, selectedListings: action.payload }
    case 'SET_SHOW_CHARGER_MODAL':
      return { ...state, showChargerModal: action.payload }
    case 'SET_SHOW_LISTING_MODAL':
      return { ...state, showListingModal: action.payload }
    case 'SET_SHOW_SEARCH_QUERY':
      return { ...state, showSearchQuery: action.payload }
    case 'SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } }
    default:
      return state
  }
}
