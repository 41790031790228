import React from 'react'
import { Table, Checkbox, Tooltip, Row, Col } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import { pinSVG } from 'assets/svg/pin'
import { useAppState } from 'state'
import { ChargersColumns } from './ChargerColumns'

import { ChargersPerTenant } from 'models/charger'
import { NewListing } from 'models/listing'
import { TenantRef } from 'models/tenant'
import { tenantGreySVG } from 'assets/svg/tenant'
import { Links } from 'components/routes/paths'
import { theme } from 'theme'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { PaginationMeta } from 'models/pagination'
import { ChargersPerTenantFilter } from 'models/filter'

interface ChargersTableProps {
  chargers: ChargersPerTenant[]
  loading: boolean
  pagination?: PaginationMeta
  selectedRowKeys: number[]
  filters: ChargersPerTenantFilter
  onSelectChange: (record: NewListing, selected: boolean) => void
  onSelectAllChange: (checked: boolean) => void
  onSelectTenantChange: (tenantId: string, listings: NewListing[], checked: boolean) => void
  onPageChange: (page: number, pageSize: number) => void
  onConnectivityStatusChange: (connectivityStatus?: string) => void
}

const ChargersTable: React.FC<ChargersTableProps> = ({
  chargers,
  loading,
  pagination,
  selectedRowKeys,
  filters,
  onSelectAllChange,
  onSelectChange,
  onSelectTenantChange,
  onPageChange,
  onConnectivityStatusChange,
}) => {
  const { currentUser } = useAppState()

  const isAdmin = currentUser?.role === 'admin'

  const { viewSiteDetailsText } = useTenantTranslation()

  const indeterminate =
    selectedRowKeys.length > 0 &&
    selectedRowKeys.length < chargers.reduce((total, tenant) => total + tenant.listings.length, 0)

  const checkAll = selectedRowKeys.length >= chargers.reduce((acc, charger) => acc + charger.listings.length, 0)

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectChange,
    hideSelectAll: true,
  }

  const CustomBodyWrapper = (tenant: TenantRef, listings: NewListing[]) => (props: any) => {
    const { children, ...restProps } = props

    if (!tenant || !listings) {
      return <tbody {...restProps}>{children}</tbody>
    }

    const isAllTenantSelected = listings.every((listing) => selectedRowKeys.includes(listing.id))

    return (
      <tbody {...restProps}>
        <tr className="exclude-from-style">
          <td colSpan={11} style={{ padding: '16px 16px 16px 8px' }}>
            <Row justify="space-between" align="middle" style={{ gap: '9px' }}>
              {isAdmin && (
                <Checkbox
                  checked={isAllTenantSelected}
                  onChange={(e: CheckboxChangeEvent) => onSelectTenantChange(tenant.id, listings, e.target.checked)}
                />
              )}
              <Row align="middle" justify="space-between" style={{ flex: 1 }}>
                <Col className={`tenant-info ${isAdmin ? 'admin-user' : 'non-admin-user'}`}>
                  <span className="pin-icon">{pinSVG}</span>
                  <span className="paragraph-01-regular">{tenant.displayName ? tenant.displayName : tenant.name}</span>
                </Col>
                {isAdmin && (
                  <Col className="pointer" style={{ marginRight: '1.5rem' }}>
                    <a
                      key={tenant.id}
                      href={Links.tenant({ tenantId: tenant.id })}
                      style={{ color: theme.colors.softBlack, marginLeft: '2.2rem' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Tooltip title={viewSiteDetailsText}>{tenantGreySVG}</Tooltip>
                    </a>
                  </Col>
                )}
              </Row>
            </Row>
          </td>
        </tr>
        {children}
      </tbody>
    )
  }

  const renderAssociatedTenant = (tenant: TenantRef, listings: NewListing[]) => ({
    body: {
      wrapper: CustomBodyWrapper(tenant, listings),
    },
  })

  return (
    // A custom select all checkboxes from each tenant
    <div className="chargers-table-wrapper">
      {chargers.length !== 0 && isAdmin && (
        <Checkbox
          indeterminate={indeterminate}
          className="custom-select-all-checkbox"
          onChange={(e: CheckboxChangeEvent) => onSelectAllChange(e.target.checked)}
          checked={checkAll} // there is a 'checkAll' property but this is a custom implementation for all chargers in all tenants
        />
      )}

      {/* Render a table for each tenant */}
      {chargers.map((charger, index) => (
        <Table
          key={charger.tenant.id}
          loading={loading}
          rowKey="id"
          className="custom-table-selected-row-color"
          columns={ChargersColumns(onConnectivityStatusChange, filters.connectivityStatus)}
          dataSource={charger.listings}
          showHeader={index === 0}
          pagination={
            index === chargers.length - 1
              ? {
                  hideOnSinglePage: false,
                  position: ['bottomCenter'],
                  total: pagination?.totalEntries,
                  current: pagination?.currentPage,
                  pageSize: pagination?.perPage,
                  showSizeChanger: false,
                  onChange: onPageChange,
                }
              : false
          }
          rowSelection={isAdmin ? rowSelection : undefined}
          components={renderAssociatedTenant(charger.tenant, charger.listings)} // to render associated tenant
          style={{ marginBottom: '2rem' }} // to give space between tenants
          scroll={{ x: '1275px' }}
        />
      ))}
    </div>
  )
}

export default ChargersTable
