import { Col, Row, Spin } from 'antd'

import { ChargerInfo, ChargerStationInfo, ConnectorInfo } from 'models/chargerv2'
import { Section } from 'atom/box'
import StationOverviewCardGroup from '../components/cards'
import StationOverviewCharts from 'features/home/StationOverviewChart/StationOverviewCharts'
import { TenantRef } from 'models/tenant'
import { MasterAccountRef } from 'models/master-account'

interface props {
  chargerInfo: ChargerInfo | undefined
  connectorInfo?: ConnectorInfo
  wsLoading: boolean
  chargerStationInfo?: ChargerStationInfo
  tenants?: TenantRef[]
  masterAccount?: MasterAccountRef
}

const StationOverviewSection: React.FC<props> = ({
  connectorInfo,
  chargerInfo,
  wsLoading,
  chargerStationInfo,
  tenants,
  masterAccount,
}) => {
  return (
    <Spin spinning={wsLoading}>
      <Section>
        <Row gutter={[24, 15]} style={{ minHeight: '430px' }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <StationOverviewCardGroup wsLoading={wsLoading} chargerInfo={chargerInfo} connectorInfo={connectorInfo} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
            <Row gutter={[24, 15]} style={{ height: '100%' }}>
              <StationOverviewCharts
                loading={wsLoading}
                chargerStationInfo={chargerStationInfo}
                connectorInfo={connectorInfo}
                tenants={tenants}
                masterAccount={masterAccount}
              />
            </Row>
          </Col>
        </Row>
      </Section>
    </Spin>
  )
}

export default StationOverviewSection
