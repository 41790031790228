import { Button, DatePicker, Space } from 'antd'
import { RangePickerProps } from 'antd/es/date-picker'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { renderFormatMessage, useFormatMessage } from '../../helpers/intl'
import { CaseConverter } from '../../services/data-provider/case-converter'
import { useAppState } from '../../state'
import { refreshIconSVG } from 'assets/svg/refresh'

const { RangePicker } = DatePicker
interface props {
  startDate?: moment.Moment
  endDate?: moment.Moment
  onChangeDate: (dates: any) => void
  calendarRanges: string[]
  previousQuarter?: any
  disabled?: boolean
  insideModal?: boolean
  enableFutureDate?: boolean
}

const setCalendarRanges = (calendarRanges: string[], otherRanges?: any) => {
  let ranges: Record<string, [moment.Moment, moment.Moment]> = {
    Today: [moment().startOf('day'), moment()],
    'Past 7 Days': [moment().subtract(6, 'day').startOf('day'), moment().endOf('day')],
    'Past 15 Days': [moment().subtract(14, 'day').startOf('day'), moment().endOf('day')],
    'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
    'Last Quarter': [moment().subtract(3, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
    'This Year': [moment().startOf('year'), moment().endOf('day')],
    'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
    'Custom Date': [moment().subtract(1, 'months').startOf('month'), moment()],
    ...otherRanges,
  }
  const newKeys: Record<string, string> = {
    Today: calendarRanges[0],
    'Past 7 Days': calendarRanges[1],
    'Past 15 Days': calendarRanges[2],
    'Last Month': calendarRanges[3],
    'Last Quarter': calendarRanges[4],
    'This Year': calendarRanges[5],
    'Last Year': calendarRanges[6],
    'Custom Date': calendarRanges[7],
  }
  let caseConverter = new CaseConverter()
  const updatedRanges = caseConverter.renameKeys(ranges, newKeys)
  return updatedRanges
}

export const CustomDateRangePicker: React.FC<props> = ({
  startDate,
  endDate,
  onChangeDate,
  calendarRanges,
  previousQuarter,
  disabled,
  insideModal = false,
  enableFutureDate,
}) => {
  let updateCalendarRanges

  if (previousQuarter) {
    updateCalendarRanges = setCalendarRanges(calendarRanges, previousQuarter)
  } else {
    updateCalendarRanges = setCalendarRanges(calendarRanges)
  }

  let fieldProps: RangePickerProps = {
    ranges: updateCalendarRanges,
    onChange: onChangeDate,
    disabledDate: (current: moment.Moment) => current > moment().endOf('day'),
    allowClear: false,
  }

  if (startDate && endDate) {
    fieldProps.defaultValue = [startDate, endDate]
    fieldProps.value = [startDate, endDate]
  }

  if (enableFutureDate) {
    delete fieldProps.disabledDate
  }

  const uniqueDropdownId = `date-picker-dropdown-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`

  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(0)
  const datePickerRef = useRef<any>(null)
  const [customPresetOpen, setCustomPresetOpen] = useState(false)

  const { language } = useAppState()

  const customDateTxt = useFormatMessage('dashboard.calendar.customdate', 'Custom Date')

  const customClickHandler = (currentDatePicker: Element) => (e: Event) => {
    if (e.target) {
      //@ts-ignore
      const target = e.target.closest('.ant-picker-preset')
      const isInsideCurrentDatePicker = currentDatePicker?.contains(target)

      if (target && isInsideCurrentDatePicker) {
        var elems = currentDatePicker?.querySelector('.active-preset')
        if (elems !== null) {
          elems.classList.remove('active-preset')
          elems.classList.remove('custom-preset-active')
          currentDatePicker?.querySelector('.ant-picker-panel-container')?.classList.remove('custom-preset-active')
        }
        target.classList.add('active-preset')
        const text = currentDatePicker?.querySelector('.active-preset .ant-tag')

        if (text?.innerHTML === customDateTxt) {
          setOpen(true)
          setCustomPresetOpen(true)
          datePickerRef && datePickerRef?.current?.focus()
          target.classList.add('custom-preset-active')
          currentDatePicker?.querySelector('.ant-picker-panel-container')?.classList.add('custom-preset-active')
        } else {
          setOpen(false)
          setCustomPresetOpen(false)
        }
      }
    }
  }

  const renderCustomRadios = () => {
    const currentDatePicker = document.querySelector(`.${uniqueDropdownId}`)
    if (currentDatePicker) {
      for (let i = 1; i <= currentDatePicker?.querySelectorAll(`.ant-picker-ranges .ant-picker-preset`).length; i++) {
        if (i === 2) {
          currentDatePicker
            ?.querySelector(`.ant-picker-ranges .ant-picker-preset:nth-of-type(3)`)
            ?.classList.add('active-preset')
        }
        currentDatePicker
          ?.querySelector(`.ant-picker-ranges .ant-picker-preset:nth-of-type(${i})`)
          ?.insertAdjacentHTML(
            'afterbegin',
            `<label class="ant-checkbox-wrapper"><span class="ant-radio ${
              i === 2 ? 'ant-radio-checked' : ''
            }"><input type="radio" class="ant-checkbox-input" value=""><span class="ant-radio-inner"></span></span></label>`,
          )

        currentDatePicker
          ?.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i})`)
          ?.addEventListener('click', function () {
            var elemt = currentDatePicker?.querySelectorAll(
              `.ant-picker-ranges  .ant-picker-preset:not(:nth-of-type(${i})) span.ant-radio.ant-radio-checked`,
            )
            if (elemt.length > 0) {
              //@ts-ignore
              elemt[0].click()
            }
            //@ts-ignore
            currentDatePicker
              ?.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) .ant-checkbox-input`)
              //@ts-ignore
              ?.click()
            currentDatePicker
              ?.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) span.ant-radio`)
              ?.classList.toggle('ant-radio-checked')
            //@ts-ignore
            currentDatePicker?.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(${i}) > span`)?.click()
            //@ts-ignore
          })
      }
    }
  }

  useEffect(() => {
    if (open && count === 0) {
      renderCustomRadios()
      setCount(1)
    }
    const currentDatePicker = document.querySelector(`.${uniqueDropdownId}`)
    if (currentDatePicker) {
      if (open) {
        document.addEventListener('click', customClickHandler(currentDatePicker))
      } else {
        document.removeEventListener('click', customClickHandler(currentDatePicker))
      }
    }
  }, [open])

  useEffect(() => {
    setCount(0)
    setOpen(false)
    const currentDatePicker = document.querySelector(`.${uniqueDropdownId}`)
    //@ts-ignore
    currentDatePicker?.querySelector(`.ant-picker-ranges  .ant-picker-preset:nth-of-type(3) > span`)?.click()
  }, [language])

  return (
    <RangePicker
      {...fieldProps}
      open={open}
      onClick={() => setOpen(true)}
      ref={datePickerRef}
      disabled={disabled}
      renderExtraFooter={() => (
        <div className="extra-footer-area">
          <div
            onClick={() => {
              setTimeout(() => {
                onChangeDate(datePickerRef.current.props.ranges[customDateTxt])
              }, 100)
            }}
            className="refresh-button"
          >
            {refreshIconSVG}
            <span className="regular-cap">
              {renderFormatMessage('dashboard.tenantPage.detailTab.resetbtn', 'Reset')}
            </span>
          </div>
          <div className="apply-area">
            <Space>
              <Button
                onClick={() => {
                  setOpen(false)
                  setCustomPresetOpen(false)
                  setTimeout(() => {
                    datePickerRef && datePickerRef.current.blur()
                  }, 100)
                }}
                type="ghost"
              >
                <span className="regular-cap extra-button">
                  {renderFormatMessage('dashboard.text.cancel', 'Cancel')}
                </span>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(false)
                  setCustomPresetOpen(false)
                  setTimeout(() => {
                    datePickerRef && datePickerRef.current.blur()
                  }, 100)
                }}
              >
                <span className="regular-cap extra-button">{renderFormatMessage('dashboard.text.apply', 'Apply')}</span>
              </Button>
            </Space>
          </div>
        </div>
      )}
      onBlur={() => {
        customPresetOpen ? setOpen(true) : setOpen(false)
      }}
      format="MMM DD, YYYY"
      dropdownClassName={`${uniqueDropdownId} ${insideModal ? 'inside-modal' : ''}`}
      className={`rangePicker ${language}`}
      getPopupContainer={insideModal ? (triggerNode) => triggerNode?.parentNode as HTMLElement : undefined}
    />
  )
}
