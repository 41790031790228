import { Modal, Row } from 'antd'
import { useState } from 'react'

import UptimeEquation from 'assets/images/uptime-equation.png'
import UtilizationEquation from 'assets/images/utilization-equation.png'
import { CoreCollapse } from 'components/collapse/core-collapse'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'

interface ChargerTabInfoModalProps {
  onCancel: () => void
}

export const ChargerTabInfoModal: React.FC<ChargerTabInfoModalProps> = ({ onCancel }) => {
  const [activeCollapsePanelKey, setActiveCollapsePanelKey] = useState<string>('')

  const handleCollapseChange = (key: string | string[]) => {
    setActiveCollapsePanelKey((prevKey) =>
      prevKey === (Array.isArray(key) ? key[0] : key) ? '' : Array.isArray(key) ? key[0] : key,
    )
  }

  const {
    chargersTabUtilizationRateText,
    chargersTabUtilizationRateDescriptionText,
    chargersTabUptimePercentageText,
    chargersTabUptimePercentageDescriptionText,
  } = useTenantTranslation()

  const descriptions = [
    {
      id: 1,
      title: chargersTabUtilizationRateText,
      body: (
        <>
          {chargersTabUtilizationRateDescriptionText}
          <Row justify="center">
            <img src={UtilizationEquation} alt="utilization calculation" style={{ width: '60%' }} />
          </Row>
        </>
      ),
    },
    {
      id: 2,
      title: chargersTabUptimePercentageText,
      body: (
        <>
          {chargersTabUptimePercentageDescriptionText}
          <img src={UptimeEquation} alt="uptime calculation" style={{ width: '100%' }} />
        </>
      ),
    },
  ]

  return (
    <Modal
      closable={false}
      visible={true}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: '10px 25px 24px 25px' }}
      className="remove-bottom-border"
    >
      <Row gutter={[8, 11]}>
        <CoreCollapse
          descriptions={descriptions}
          activeCollapsePanelKey={activeCollapsePanelKey}
          onCollapseChange={handleCollapseChange}
        />
      </Row>
    </Modal>
  )
}
