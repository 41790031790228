import { Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { GoodNetworkSignalSVG, NoNetworkSignalSVG, NetworkSVG } from 'assets/svg/network'
import { UnderlinedLink } from 'components/link'
import { Links } from 'components/routes/paths'
import ServiceStatusBadge from 'components/connectors/ServiceStatusBadge'

import { renderFormatMessage } from 'helpers/intl'
import UptimePercentage from './cell/UptimePercentage'
import { ChargerUptime } from 'models/charger'

import { useChargerTranslation } from 'hooks/translation/useChargerTranslation'
import { DateTimeV2 } from 'components/date-time'

export const ChargersUptimeColumns = () => {
  const {
    chargerIdText,
    serialNumberText,
    swtchCareText,
    utilizationRateText,
    uptimePercentageText,
    serviceStatusText,
  } = useChargerTranslation()

  const columns: ColumnsType<ChargerUptime> = [
    {
      title: (
        <Tooltip
          title={renderFormatMessage(
            'dashboard.text.connectivity.description',
            'The current connectivity status of the charger',
          )}
        >
          {NetworkSVG}
        </Tooltip>
      ),
      width: 80,
      render: (chargerListing: ChargerUptime) =>
        chargerListing.online ? (
          <Tooltip title={renderFormatMessage('dashboard.text.online', 'Online')}>{GoodNetworkSignalSVG}</Tooltip>
        ) : (
          <Tooltip title={renderFormatMessage('dashboard.text.offline', 'Offline')}>{NoNetworkSignalSVG}</Tooltip>
        ),
    },
    {
      title: chargerIdText,
      key: 'listing',
      render: (charger: ChargerUptime) => {
        if (!charger.listing) return '-'

        return (
          <UnderlinedLink to={Links.listing({ listingId: `${charger.listing.id}` })}>
            <Typography.Text className="hover-underline" ellipsis={{ tooltip: charger.listing.title || '-' }}>
              {charger.listing.title || '-'}
            </Typography.Text>
          </UnderlinedLink>
        )
      },
    },
    {
      title: serialNumberText,
      render: (charger: ChargerUptime) => (
        <UnderlinedLink
          to={Links.charger({
            chargerId: charger?.id || '',
            connectorId: charger?.connector.id || '',
          })}
        >
          <Typography.Text className="hover-underline" ellipsis={{ tooltip: charger.chargePointSerialNumber || '-' }}>
            {charger.chargePointSerialNumber || '-'}
          </Typography.Text>
        </UnderlinedLink>
      ),
    },
    {
      title: renderFormatMessage('dashboard.text.initialActivationDate', 'Initial Activation Date'),
      key: 'initialActivationDate',
      render: (charger: ChargerUptime) =>
        charger.listing?.initialActivationTime ? (
          <DateTimeV2 alignLeft ignoreTime date={charger.listing.initialActivationTime} />
        ) : (
          '-'
        ),
    },
    {
      title: serviceStatusText,
      key: 'serviceStatus',
      render: (charger: ChargerUptime) =>
        charger?.connector?.serviceStatus ? <ServiceStatusBadge state={charger.connector.serviceStatus} /> : null,
    },
    { title: swtchCareText, dataIndex: 'swtchCare' },
    {
      title: utilizationRateText,
      key: 'utilizationRate',
      render: (chargerListing: ChargerUptime) => (
        <UptimePercentage isColorRequired={false} percentage={chargerListing.utilizationRate} />
      ),
    },
    {
      title: uptimePercentageText,
      key: 'uptimePercentage',
      render: (charger: ChargerUptime) => (
        <UptimePercentage isColorRequired={true} percentage={charger.uptimePercentage} />
      ),
    },
  ]
  return columns
}
