import moment from 'moment'
import { useEffect, useState } from 'react'
import { Form, Input, Modal } from 'antd'
import { Store } from 'antd/lib/form/interface'

import { ButtonModal } from 'atom/button'
import { AlertError } from 'components/error'
import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { CustomSuccessMessage } from 'components/peakShaving/message'
import { PeakShavingAggregatorNameSelector } from 'components/selector/peak-shaving-aggregator-name-selector'

import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { usePeakShavingTranslation } from 'hooks/translation/usePeakShavingTranslation'

import { SwtchApiError } from 'models/error'
import { PeakShavingProgram } from 'models/peak-shaving'

import { CreatePeakShavingProgram, UpdatePeakShavingProgram } from 'services/data-provider/peak-shaving'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit: (updatedProgram?: PeakShavingProgram) => void
  initialValues?: Store //This indicates Edit modal
}

export const CreateNewProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit, initialValues }) => {
  const [loading, setLoading] = useState(false)
  const [CreateNewProgramForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const messageKey = initialValues ? 'programDetailsMessageKey' : 'createNewProgramMessageKey'

  const { calendarRanges } = useCalendarTransactions()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'))
  const [endDate, setEndDate] = useState(moment(moment()))

  useEffect(() => {
    if (initialValues) {
      setStartDate(moment(initialValues?.startDate))
      setEndDate(moment(initialValues?.endDate))
    }
  }, [initialValues])

  const buttonProps = {
    loading,
    disabled: loading,
    style: { width: '49%' },
  }

  const {
    createNewProgramText,
    updateProgramText,
    cancelText,
    submitText,
    isRquiredText,
    dateText,
    nameText,
    aggregatorNameText,
    descriptionText,
    programCreatedSuccessText,
    programUpdatedSuccessText,
    participationDetailsText,
    participationDetailsPlaceholderText,
    eventNotificationDetailsText,
    eventNotificationDetailsPlaceholderText,
    cannotExceedText,
  } = usePeakShavingTranslation()

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleFormSubmit = () => {
    CreateNewProgramForm.validateFields().then((values) => {
      setLoading(true)

      const body = {
        ...values,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        id: initialValues?.id,
      }

      //Need this since removing parenthesis disable then and catch functionality of UpdatePeakShavingProgram
      ;(initialValues ? UpdatePeakShavingProgram(body) : CreatePeakShavingProgram(body))
        .then((resp) => {
          const responseMessage = initialValues
            ? programUpdatedSuccessText
            : programCreatedSuccessText.replace('-1', resp.name)

          CustomSuccessMessage({ key: messageKey, response: responseMessage })
          onSubmit(resp)
          CreateNewProgramForm.resetFields()
        })
        .catch((err: SwtchApiError) => setError(err))
        .finally(() => setLoading(false))
    })
  }

  return (
    <Modal
      title={<h5 className="heading-05-regular">{initialValues ? updateProgramText : createNewProgramText}</h5>}
      visible={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      width={540}
      style={{ top: 20 }}
      footer={[
        <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} {...buttonProps} />,
        <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={submitText} {...buttonProps} />,
      ]}
      className={`createNewProgramModal ${error ? 'ant-modal-error' : ''}`}
    >
      <AlertError error={error} />
      <Form form={CreateNewProgramForm} initialValues={initialValues} layout="vertical" requiredMark={false}>
        <Form.Item
          label={<p className="paragraph-02-regular">{nameText}</p>}
          name="name"
          rules={[{ required: true, message: isRquiredText.replace('-1', nameText) }]}
          style={{ marginTop: '2rem' }}
        >
          <Input disabled={!!initialValues} placeholder="Ontario’s Global Adjustment Program" />
        </Form.Item>

        <Form.Item
          label={<span className="paragraph-02-regular">{aggregatorNameText}</span>}
          name="aggregatorName"
          rules={[{ required: true, message: isRquiredText.replace('-1', aggregatorNameText) }]}
        >
          <PeakShavingAggregatorNameSelector placeholder="Peak Power" />
        </Form.Item>

        <Form.Item label={<span className="paragraph-02-regular">{dateText}</span>}>
          <CustomDateRangePicker
            startDate={startDate}
            endDate={endDate}
            onChangeDate={onChangeDate}
            calendarRanges={calendarRanges}
            insideModal
            enableFutureDate
          />
        </Form.Item>

        <Form.Item
          label={<span className="paragraph-02-regular">{participationDetailsText}</span>}
          name="participationWindow"
          rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', participationDetailsText) }]}
        >
          <Input.TextArea rows={3} placeholder={participationDetailsPlaceholderText} />
        </Form.Item>

        <Form.Item
          label={<span className="paragraph-02-regular">{eventNotificationDetailsText}</span>}
          name="eventNotification"
          rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', eventNotificationDetailsText) }]}
        >
          <Input.TextArea rows={3} placeholder={eventNotificationDetailsPlaceholderText} />
        </Form.Item>

        <Form.Item
          label={<span className="paragraph-02-regular">{descriptionText}</span>}
          name="initiative"
          rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', descriptionText) }]}
        >
          <Input.TextArea rows={5} placeholder={descriptionText} showCount />
        </Form.Item>
      </Form>
    </Modal>
  )
}
