import { useEffect, useState } from 'react'
import { Badge, Tooltip } from 'antd'

import { NetworkSVG } from 'assets/svg/network'
import { theme } from 'theme'
import { renderFormatMessage } from 'helpers/intl'

const { dangerPrimary, greenBase } = theme.colors

interface TogglingBadgeProps {
  onConnectivityStatusChange: (newConnectivityStatus?: string) => void
  defaultConnectivityStatus?: string
}

const TogglingBadge: React.FC<TogglingBadgeProps> = ({ onConnectivityStatusChange, defaultConnectivityStatus }) => {
  // Initialize color state based on defaultConnectivityStatus
  const getInitialColor = (status?: string) => {
    if (status === 'online') return greenBase
    if (status === 'offline') return dangerPrimary
    return 'none'
  }

  const [colorState, setColorState] = useState(getInitialColor(defaultConnectivityStatus))

  // Effect to synchronize color state with defaultConnectivityStatus changes from parent
  useEffect(() => {
    setColorState(getInitialColor(defaultConnectivityStatus))
  }, [defaultConnectivityStatus])

  const handleToggleConnectivityStatus = () => {
    // Toggle between 'online', 'offline', and undefined
    if (colorState === 'none') {
      setColorState(greenBase)
      onConnectivityStatusChange('online')
    } else if (colorState === greenBase) {
      setColorState(dangerPrimary)
      onConnectivityStatusChange('offline')
    } else {
      setColorState('none')
      onConnectivityStatusChange(undefined)
    }
  }

  return (
    <Tooltip
      title={renderFormatMessage(
        'dashboard.text.connectivity.description',
        'The current connectivity status of the charger',
      )}
    >
      <Badge
        dot={colorState !== 'none'}
        color={colorState !== 'none' ? colorState : undefined}
        className="pointer badge"
      >
        <NetworkSVG onClick={handleToggleConnectivityStatus} />
      </Badge>
    </Tooltip>
  )
}

export default TogglingBadge
