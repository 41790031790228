import { useState } from 'react'
import { Modal, Row } from 'antd'

import { GlossaryDrawer } from './GlossaryDrawer'
import { GlossaryItem } from 'models/reports'
import { renderFormatMessage } from 'helpers/intl'
import { useAssembleReportWithGlossaries } from 'hooks/useReportWithGlossaries'
import { CoreCollapse } from 'components/collapse/core-collapse'

interface ReportInfoModalProps {
  onCancel: () => void
}

export const ReportInfoModal: React.FC<ReportInfoModalProps> = ({ onCancel }) => {
  const reportDescriptions = useAssembleReportWithGlossaries()

  const [activeCollapsePanelKey, setActiveCollapsePanelKey] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedGlossary, setSelectedGlossary] = useState<GlossaryItem[]>([])
  const [selectedTitle, setSelectedTitle] = useState<string>('')

  const handleCollapseChange = (key: string | string[]) => {
    setActiveCollapsePanelKey((prevKey) =>
      prevKey === (Array.isArray(key) ? key[0] : key) ? '' : Array.isArray(key) ? key[0] : key,
    )
  }

  const handleViewMore = (glossary: GlossaryItem[], title: string) => {
    setSelectedGlossary(glossary)
    setSelectedTitle(title)
    setOpenDrawer(true)
  }

  const handleCloseDrawer = () => setOpenDrawer(false)

  return (
    <>
      <Modal
        title={
          <div className="heading-05-regular">
            {renderFormatMessage('dashboard.text.reportOverview', 'Report Overview')}
          </div>
        }
        closable={true}
        visible={true}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ padding: '10px 25px 24px 25px' }}
        className="remove-bottom-border"
      >
        <Row gutter={[8, 11]}>
          <CoreCollapse
            descriptions={reportDescriptions}
            activeCollapsePanelKey={activeCollapsePanelKey}
            onCollapseChange={handleCollapseChange}
            onViewMore={handleViewMore}
          />
        </Row>
      </Modal>

      <GlossaryDrawer
        visible={openDrawer}
        glossary={selectedGlossary}
        title={selectedTitle}
        onClose={handleCloseDrawer}
      />
    </>
  )
}
