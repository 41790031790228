import { apiMetabaseClient } from './client'
import { addQueryString } from '../../helpers/url'
import configProvider from '../../config'
import { log } from '../../logger'
import { MetabaseReportsResponse } from '../../models/reports'
import { reportsQuery } from '../../helpers/filter/reports'
import { ReportFilter } from '../../models/filter'

export async function GetMetabaseReport(params: ReportFilter): Promise<MetabaseReportsResponse> {
  let url = '/report/get-metabase-report-url'

  const queryParams = reportsQuery(params)

  url = addQueryString(url, queryParams)

  log('GetMetabaseReport', url)

  if (configProvider.config.mockEnabled) {
    return {
      data: [{ url: 'https://google.ca' }],
      code: 200,
    }
  }

  return apiMetabaseClient(url, {})
}
