import {
  ParticipantsFilter,
  PeakShavingEventFilter,
  PeakShavingEventLogsFilter,
  PeakShavingProgramFilter,
} from '../../models/filter'

export const peakShavingEventQuery = (
  f: PeakShavingEventFilter,
  startDate: moment.Moment,
  endDate: moment.Moment,
): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {
    start_date: `${startDate.format('YYYY-MM-DD')}`,
    end_date: `${endDate.format('YYYY-MM-DD')}`,
  }
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  if (f.frequency) {
    queryParams['frequency'] = `${f.frequency}`
  }

  let refs = ''
  if (f.tenants && f.tenants.length > 0) {
    refs += `${f.tenants.map((t) => t.name)}`
  }

  if (f.listingTitle && f.listingTitle.length !== 0) {
    refs += `${refs ? ',' : ''}${f.listingTitle.join(',')}`
  }

  if (refs) {
    queryParams['refs'] = refs
  }

  if (f.programs && f.programs.length !== 0) {
    queryParams['program_ids'] = `${f.programs.join(',')}`
  }

  if (f.aggregatorName) {
    queryParams['aggregator_name'] = `${f.aggregatorName}`
  }

  return queryParams
}

export const participantsQuery = (f: ParticipantsFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  let refs = ''
  if (f.refs) {
    refs += f.refs
  }

  if (f.tenants && f.tenants.length > 0) {
    refs += `${refs ? ',' : ''}${f.tenants.map((t) => t.name)}`
  }

  if (f.listingTitle && f.listingTitle.length !== 0) {
    refs += `${refs ? ',' : ''}${f.listingTitle.join(',')}`
  }

  if (refs) {
    queryParams['ref'] = refs
  }

  if (f.programs && f.programs.length !== 0) {
    queryParams['program_ids'] = `${f.programs.join(',')}`
  }

  return queryParams
}

export const peakShavingEventLogQuery = (f: PeakShavingEventLogsFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.eventDateOrder) {
    queryParams['event_date_order'] = `${f.eventDateOrder}`
  }

  return queryParams
}

export const peakShavingProgramQuery = (f: PeakShavingProgramFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.aggregatorName) {
    queryParams['aggregator_name'] = `${f.aggregatorName}`
  }

  if (f.programs && f.programs.length !== 0) {
    queryParams['ids'] = `${f.programs.join(',')}`
  }

  return queryParams
}

//Old version used in other api
export const peakShavingEventQueryV1 = (f: PeakShavingEventFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  if (f.frequency) {
    queryParams['frequency'] = `${f.frequency}`
  }

  return queryParams
}
