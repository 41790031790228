import moment from 'moment'
import momentTz from 'moment-timezone'
import { useIntl } from 'react-intl'

import { AlignedContentLeft, AlignedContentRight } from './wrapper'
import { theme } from 'theme'

interface props {
  date: moment.Moment | string
  timezone: string
}

interface DateTimeV2Props extends Omit<props, 'timezone'> {
  isSidePanel?: boolean
  timezone?: string
  inlineDateTime?: boolean
  ignoreSeconds?: boolean
  alignLeft?: boolean
  ignoreTime?: boolean
}

// Utility function to handle date conversion.
const convertDate = (date: moment.Moment | string): moment.Moment => {
  return typeof date === 'string' ? moment(date) : date
}

export const DateTime: React.FC<props> = ({ date, timezone }) => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const format = 'YYYY-MM-DD, HH:mm:ss'
  return (
    <span>
      {date.format(format)}
      <small> {momentTz.tz(date, timezone).format('(Z)')}</small>
    </span>
  )
}

export const DateTimeV2: React.FC<DateTimeV2Props> = ({
  date,
  timezone,
  isSidePanel,
  inlineDateTime,
  ignoreSeconds,
  ignoreTime,
  alignLeft,
}) => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const convertedDate = convertDate(date)

  // Format based on whether `ignoreTime` is true or false
  const formatDate = 'YYYY/MM/DD'
  const formatTime = timezone ? (ignoreSeconds ? 'HH:mm (Z)' : 'HH:mm:ss (Z)') : ignoreSeconds ? 'HH:mm' : 'HH:mm:ss'

  const modifiedTimestamp = ignoreTime
    ? '' // Ignore the time part if `ignoreTime` is true
    : (timezone ? momentTz.tz(convertedDate, timezone) : convertedDate).format(formatTime)

  const content = (
    <>
      <span className={inlineDateTime ? 'paragraph-04-light opacity-06' : ''}>{convertedDate.format(formatDate)}</span>
      {!ignoreTime && (
        <span className="paragraph-04-light opacity-06" style={{ color: isSidePanel ? theme.colors.softBlack : '' }}>
          {' '}
          {modifiedTimestamp}
        </span>
      )}
    </>
  )

  return alignLeft ? (
    <AlignedContentLeft inlineDateTime={inlineDateTime}>{content}</AlignedContentLeft>
  ) : (
    <AlignedContentRight inlineDateTime={inlineDateTime}>{content}</AlignedContentRight>
  )
}

export const TenantTimezone: React.FC<{ timezone: string | null }> = ({ timezone }) => {
  if (!timezone) {
    return null
  }
  const tenantTimezone = moment.tz(timezone).format('z')
  return <span>({tenantTimezone})</span>
}

export const DateTimeFromNow: React.FC<{ date?: string }> = ({ date }) => {
  const { formatMessage } = useIntl()
  if (!date) {
    return null
  }
  const now = moment()
  const then = moment(date)
  const duration = moment.duration(now.diff(then))

  const timeUnits = [
    { value: duration.years(), secondValue: duration.months(), id: 'time.years', singular: 'yr', plural: 'yrs' },
    { value: duration.months(), secondValue: duration.weeks(), id: 'time.months', singular: 'mo', plural: 'mos' },
    { value: duration.weeks(), secondValue: duration.days(), id: 'time.weeks', singular: 'wk', plural: 'wks' },
    { value: duration.days(), secondValue: duration.hours(), id: 'time.days', singular: 'd', plural: 'd' },
    { value: duration.hours(), secondValue: duration.minutes(), id: 'time.hours', singular: 'hr', plural: 'hs' },
    { value: duration.minutes(), secondValue: duration.seconds(), id: 'time.minutes', singular: 'min', plural: 'mins' },
    { value: duration.seconds(), secondValue: duration.milliseconds(), id: 'time.seconds', singular: 's', plural: 's' },
  ]

  const { value, secondValue, id, singular, plural } =
    timeUnits.find((unit) => unit.value > 0) || timeUnits[timeUnits.length - 1]

  const displayValue = Math.ceil(value >= 1 ? value + (secondValue >= 1 ? secondValue * 0.1 : 0) : value)

  const timeUnit = formatMessage(
    { id, defaultMessage: `{count, plural, one {# ${singular}} other {# ${plural}}}` },
    { count: displayValue },
  )

  return <div className="paragraph-04-light opacity-06">{`< ${displayValue} ${timeUnit}`}</div>
}
