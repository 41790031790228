import { Checkbox, Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { ReusableSelector } from 'components/reusableSelector/selector'
import { renderChargerSettingText } from 'helpers/tenant-tab-render-text'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { useSelector } from 'hooks/useSelector'
import { chargerSettings } from 'models/chargerv2'
import { ChargerSetting, Tenant } from 'models/tenant'
import { ReactElement } from 'react'
import { theme } from 'theme'

interface TenantServiceStatusInfoProps {
  form: FormInstance<any>
  tenant: Tenant
  disabled: boolean
  currentChargerSettings: string[]
  setChargerSettings: (chargerSettings: ChargerSetting[]) => void
}

const TenantServiceStatusInfo: React.FC<TenantServiceStatusInfoProps> = ({
  form,
  tenant,
  disabled,
  currentChargerSettings,
  setChargerSettings,
}) => {
  const { omOptInText, swtchCareText, caasText, chargerSettingsText } = useTenantTranslation()
  const { omOptinNoText, noteText } = useGeneralTranslation()
  const { multiChargerSettingsHandlePlaceholder, multiChargerSettingsHandleChange } = useSelector()

  const renderMultiChargerSettingsOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderChargerSettingText(option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }
  return (
    <>
      <Form.Item name="omOptin" label={omOptInText} rules={[{ required: false }]} className="align-center">
        <Select disabled={disabled}>
          <Select.Option value="swtch_care">{swtchCareText}</Select.Option>
          <Select.Option value="caas">{caasText}</Select.Option>
          <Select.Option value="no">{omOptinNoText}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="chargerSetting"
        label={chargerSettingsText}
        rules={[{ required: false }]}
        className="align-center"
      >
        <ReusableSelector
          showSearch
          disabled={disabled}
          selectAllOptions
          onOptionsChange={(chargerSetting: ChargerSetting[]) => {
            setChargerSettings(chargerSetting)
            form.setFieldsValue({ chargerSetting })
          }}
          onClear={() => {
            setChargerSettings([])
            form.setFieldsValue({ chargerSetting: [] })
          }}
          isSingle={false}
          placeholder={chargerSettingsText}
          dropDownList={chargerSettings}
          showMagnifySVG={false}
          maxTagCount={0}
          isDebounceFetcher={false}
          debounceFetcher={undefined}
          defaultValues={currentChargerSettings}
          handlePlaceholder={multiChargerSettingsHandlePlaceholder}
          handleOptionChange={multiChargerSettingsHandleChange}
          renderOption={renderMultiChargerSettingsOption}
        />
      </Form.Item>
      <Form.Item name="notes" label={noteText}>
        <Input.TextArea disabled={disabled} showCount maxLength={255} style={{ height: theme.space[6] }} />
      </Form.Item>
    </>
  )
}

export default TenantServiceStatusInfo
