import { Col } from 'antd'

import { CardWrapper } from 'atom/card'
import { ChargerStationInfo, ConnectorInfo } from 'models/chargerv2'
import CoreStationCard from 'components/cards/CoreStationCard'
import { useDashboardTranslation } from 'hooks/translation/useDashboardTranslation'
import { HomePageStationsChart } from 'pages/dashboard/charts/active-users/stations'
import { HomePageCurrentChart } from 'pages/dashboard/charts/active-users/current'
import { theme } from 'theme'
import { useFormatMessage } from 'helpers/intl'
import configProvider from 'config'
import { useNavigateToChargers } from 'hooks/useNavigateToChargers'
import { TenantRef } from 'models/tenant'
import { MasterAccountRef } from 'models/master-account'

interface StationOverviewChartsProps {
  loading: boolean
  chargerStationInfo?: ChargerStationInfo
  connectorInfo?: ConnectorInfo
  tenants?: TenantRef[]
  masterAccount?: MasterAccountRef
}

const StationOverviewCharts: React.FC<StationOverviewChartsProps> = ({
  loading,
  chargerStationInfo,
  connectorInfo,
  tenants,
  masterAccount,
}) => {
  const { navigateToChargers } = useNavigateToChargers(masterAccount, tenants)

  const goToOfflineChargers = () => navigateToChargers({ connectivityStatus: 'offline' })
  const goToUnderRepairChargers = () => navigateToChargers({ serviceStatus: 'repairing' })
  const goToOnlineChargers = () => navigateToChargers({ connectivityStatus: 'online' })

  const { stationsText, currentText, onlineChargers, offlineChargers, underRepairChargers } = useDashboardTranslation()
  const activeChargerCurrent = useFormatMessage(
    'dashboard.homePage.stationoverview.current.description1',
    'Active Charger Current',
  )

  const maximumChargerCurrent = useFormatMessage(
    'dashboard.homePage.stationoverview.current.description2',
    'Maximum Charger Current',
  )

  const ChartComponents = [
    {
      title: stationsText,
      label: 'stations',
      Chart: HomePageStationsChart,
      chartData: [
        {
          label: onlineChargers,
          data: chargerStationInfo?.online,
          color: theme.colors.pieGraphPrimary,
          onClick: goToOnlineChargers,
        },
        {
          label: offlineChargers,
          data: chargerStationInfo?.offline,
          color: theme.colors.pieGraphQuaternary,
          onClick: goToOfflineChargers,
        },
        {
          label: underRepairChargers,
          data:
            (chargerStationInfo?.repair ?? 0) +
            (chargerStationInfo?.other ?? 0) +
            (chargerStationInfo?.troubleshooting ?? 0),
          color: theme.colors.pieGraphQuinary,
          onClick: goToUnderRepairChargers,
        },
      ],
      chartInfo: {
        title: connectorInfo?.charging || '0',
        label: useFormatMessage('dashboard.homePage.chargingText', 'Charging'),
        min: 0,
        max: (chargerStationInfo?.online ?? 0) + (chargerStationInfo?.offline ?? 0) + (chargerStationInfo?.repair ?? 0),
        height: 90, //Height for the middle circle area
      },
    },
    {
      title: currentText,
      label: 'current',
      Chart: HomePageCurrentChart,
      chartData: [
        {
          label: activeChargerCurrent,
          data: Math.round(chargerStationInfo?.currentAmp || 0),
          color: theme.colors.pieGraphPrimary,
        },
        {
          label: maximumChargerCurrent,
          data: chargerStationInfo?.maxAmp,
          color: '#EEE',
        },
      ],
      chartInfo: {
        title: `${Math.round(chargerStationInfo?.currentAmp || 0)} A`,
        label: activeChargerCurrent,
        min: `0 A`,
        max: `${chargerStationInfo?.maxAmp} A`,
        height: 100, //Height for the middle circle area
      },
      dataFormatter: (data: string) => `(${data} A)`,
    },
  ]

  const defaultColSizes = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  }

  const customColSize = {
    itsElectric: {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 24,
      xxl: 24,
    },
  }

  return (
    <>
      {ChartComponents.filter((_, componentIndex) =>
        configProvider.config.network === 'itsElectric' ? componentIndex === 0 : true,
      ).map((card, index) => (
        <Col
          key={card.label}
          {...(configProvider.config.network in customColSize
            ? customColSize[configProvider.config.network as keyof typeof customColSize]
            : defaultColSizes)}
        >
          <CardWrapper>
            <CoreStationCard
              title={card.title}
              label={card.label}
              loading={loading}
              key={index}
              connectorInfo={connectorInfo}
              chargerStationInfo={chargerStationInfo}
              Chart={card.Chart}
              chartData={card.chartData}
              chartInfo={card.chartInfo}
              dataFormatter={card?.dataFormatter}
            />
          </CardWrapper>
        </Col>
      ))}
    </>
  )
}

export default StationOverviewCharts
