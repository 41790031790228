import {
  Charger,
  ChargerBase,
  ChargerDetails,
  ChargerRef,
  ChargerListings,
  ChargersListingsLoadMgmt,
  ChargersPerTenant,
  ServiceStatus,
  ChargerUptime,
} from '../models/charger'
import moment from 'moment'
import { mockTenant } from './tenant-mock'
import { mockListing } from './listing-mock'
import {
  ChargersListingsLoadMgmtResponse,
  ChargersListingsResponse,
  ChargersResponse,
  ChargersUptimeForTenantResponse,
} from '../models/http'
import { ConnectorServiceStatus, ConnectorStatus, connectorServiceStatus, connectorStatus } from 'models/connector'
import { mockPrice } from './price-mock'
import { mockLoiterings } from './loitering-mock'
import { ChargersPerTenantFilter } from 'models/filter'
import { mockListingTaggingResp, mockTenantTaggingResp } from './tagging-mock'
import { mockVendors } from './vendor'
import { log } from 'logger'

export const mockChargerRef: { [serialNumber: string]: ChargerRef } = {
  '1EC1-1-1824-00035': {
    id: 1,
    chargePointSerialNumber: '1EC1-1-1824-00035',
  },
  '1EC1-1-1834-00188': {
    id: 2,
    chargePointSerialNumber: '1EC1-1-1834-00188',
  },
  '1EC1-1-1834-00185': {
    id: 3,
    chargePointSerialNumber: '1EC1-1-1834-00185',
  },
  '1EC0-1-1823-00061': {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  '1920511': {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
  '1EC1-1-1834-00059': {
    id: 6,
    chargePointSerialNumber: '1EC1-1-1834-00059',
  },
  '1EC1-1-1834-00060': {
    id: 7,
    chargePointSerialNumber: '1EC1-1-1834-00060',
  },
}

export const mockCharger: { [serialNumber: string]: ChargerBase } = {
  '1EC1-1-1824-00035': {
    ...mockChargerRef['1EC1-1-1824-00035'],
    serviceStatus: 'under_repair',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00188': {
    ...mockChargerRef['1EC1-1-1834-00188'],
    serviceStatus: 'activating',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['18-Yorkville-Av-Toronto-ON-M4W2J1'],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00185': {
    ...mockChargerRef['1EC1-1-1834-00185'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['81-NavyWharf-Ct-Toronto-ON-M5B3S2'],
    online: true,
    chargingType: 'Level 2',
  },
  '1EC0-1-1823-00061': {
    ...mockChargerRef['1EC0-1-1823-00061'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['25-Bamburgh-Ci-Scarborough-ON-M1W3W2'],
    online: true,
    chargingType: 'Level 2',
  },
  '1920511': {
    ...mockChargerRef['1920511'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'EV-BOX',
    tenant: mockTenant['25-Bamburgh-Ci-Scarborough-ON-M1W3W2'],
    online: false,
    chargingType: 'Level 2',
  },
  '1EC1-1-1834-00059': {
    ...mockChargerRef['1EC1-1-1834-00059'],
    serviceStatus: 'service_mode',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['133-Hazelton-Av-Toronto-ON-M5R0A6'],
    online: true,
    chargingType: 'DC',
  },
  '1EC1-1-1834-00060': {
    ...mockChargerRef['1EC1-1-1834-00060'],
    serviceStatus: 'in_maintenance',
    chargePointModel: 'foo',
    chargePointVendor: 'LITE-ON',
    tenant: mockTenant['344-Kitchner-Ave-West-ON-M9E8E4'],
    online: false,
    chargingType: 'Level 2',
  },
}
export const mockFullCharger = {
  ...mockCharger['1EC1-1-1824-00035'],
  connectors: [
    {
      id: 1,
      connectorId: 1,
      listing: mockListing['DP01'],
      state: 'SuspendedEV',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
    {
      id: 2,
      connectorId: 2,
      listing: mockListing['DP02'],
      state: 'Charging',
      lastStatusChangedAt: moment().startOf('hour').format(),
    },
  ],
  firmwareVersion: '2',
  chargerType: 'single-port',
  serviceStatus: 'service_mode',
  maxWatt: 1700,
  lastConnectedAt: moment().startOf('week').format(),
  lastDisconnectedAt: moment().startOf('month').format(),

  createdAt: moment().format(),
  updatedAt: moment().format(),
} as Charger

export const mockChargerById: { [chargerId: number]: Charger } = {
  1: {
    ...mockCharger['1EC1-1-1824-00035'],
    connectors: [
      {
        id: 1,
        listing: mockListing['DP01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'SuspendedEV',
        serviceStatus: 'Finishing',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  2: {
    ...mockCharger['1EC1-1-1834-00188'],
    connectors: [
      {
        id: 9,
        listing: mockListing['YK01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
        serviceStatus: 'troubleshooting',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  3: {
    ...mockCharger['1EC1-1-1834-00185'],
    connectors: [
      {
        id: 3,
        listing: mockListing['NA01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Preparing',
        serviceStatus: 'on_hold',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  63: {
    ...mockCharger['1EC0-1-1823-00061'],
    connectors: [
      {
        id: 4,
        listing: mockListing['BN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Finishing',
        serviceStatus: 'driver_activating',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  5: {
    ...mockCharger['1920511'],
    connectors: [
      {
        id: 5,
        listing: mockListing['BY01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Unavailable',
        serviceStatus: 'awaiting_commissioning',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  6: {
    ...mockCharger['1EC1-1-1834-00059'],
    connectors: [
      {
        id: 6,
        listing: mockListing['DN01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Charging',
        serviceStatus: 'service_mode',
      },
      {
        id: 7,
        connectorId: 2,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Faulted',
        serviceStatus: 'under_repair',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
  7: {
    ...mockCharger['1EC1-1-1834-00060'],
    connectors: [
      {
        id: 8,
        listing: mockListing['DC01'],
        connectorId: 1,
        lastStatusChangedAt: moment().startOf('hour').format(),
        state: 'Available',
        serviceStatus: 'activating',
      },
    ],
    firmwareVersion: 'v2.1.0',
    chargerType: 'single-port',
    maxWatt: 1700,
    lastConnectedAt: moment().startOf('week').format(),
    lastDisconnectedAt: moment().startOf('month').format(),
    createdAt: moment().format(),
    updatedAt: moment().format(),
  },
}

export const mockChargers = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
  },
  {
    id: 64,
    chargePointSerialNumber: '1EC0-1-1823-00062',
  },
  {
    id: 65,
    chargePointSerialNumber: '1EC0-1-1823-00063',
  },
  {
    id: 5,
    chargePointSerialNumber: '1920511',
  },
] as ChargerRef[]

export const mockChargersDetails = [
  {
    ...mockChargerRef['1EC1-1-1824-00035'],
    phases: 'AC',
    ampere_max: 12,
    ampere_min: 12,
    ampere_fallback: 12,
    listings: [mockListing['DP01']],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00188'],
    phases: 'AC',
    ampere_max: 12,
    ampere_min: 12,
    ampere_fallback: 12,
    listings: [mockListing['YK01']],
  },

  {
    ...mockChargerRef['1EC1-1-1834-00185'],
    phases: 'AC',
    ampere_max: 10,
    ampere_min: 2,
    ampere_fallback: 8,
    listings: [mockListing['NA01']],
  },
  {
    ...mockChargerRef['1EC0-1-1823-00061'],
    phases: 'AC',
    ampere_max: 200,
    ampere_min: 2,
    ampere_fallback: 50,
    listings: [mockListing['BN01']],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00059'],
    phases: 'AC',
    ampere_max: 154,
    ampere_min: 2,
    ampere_fallback: 50,
    listings: [mockListing['DN01']],
  },
  {
    ...mockChargerRef['1920511'],
    phases: ['B', 'C'],
    ampere_max: 78,
    ampere_min: 6,
    ampere_fallback: 12,
    listings: [{ id: 5, title: 'BY01' }],
  },
  {
    ...mockChargerRef['1EC1-1-1834-00060'],
    phases: ['C', 'D'],
    ampere_max: 60,
    ampere_min: 40,
    ampere_fallback: 50,
    listings: [{ id: 7, title: 'DC01' }],
  },
] as ChargerDetails[]

export const mockChargersListings = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    listings: [{ id: 2, title: 'FR02' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 52,
    chargePointSerialNumber: 'OCPIT2',
    listings: [{ id: 47, title: 'OpenADRTestListing3' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 51,
    chargePointSerialNumber: 'OCPIT1',
    listings: [{ id: 45, title: 'OpenADRTestListing' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 48,
    chargePointSerialNumber: 'PHIWIFI',
    listings: [{ id: 4, title: 'TV01' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
  {
    id: 29,
    chargePointSerialNumber: '1EC3-1-1917-00010',
    listings: [{ id: 3, title: 'FR03' }],
    connectors: [{ id: 4, connectorId: 1 }],
  },
] as ChargerListings[]

export const mockChargersListingsLoadMgmt = [
  {
    id: 63,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    listings: [{ id: 2, title: 'FR02', addCloudLoadManagement: false }],
  },
  {
    id: 52,
    chargePointSerialNumber: 'OCPIT2',
    listings: [{ id: 47, title: 'OpenADRTestListing3', addCloudLoadManagement: false }],
  },
  {
    id: 51,
    chargePointSerialNumber: 'OCPIT1',
    listings: [{ id: 45, title: 'OpenADRTestListing', addCloudLoadManagement: true }],
  },
  {
    id: 48,
    chargePointSerialNumber: 'PHIWIFI',
    listings: [{ id: 4, title: 'TV01', addCloudLoadManagement: true }],
  },
  {
    id: 29,
    chargePointSerialNumber: '1EC3-1-1917-00010',
    listings: [{ id: 3, title: 'FR03', addCloudLoadManagement: true }],
  },
] as ChargersListingsLoadMgmt[]

export const mockChargersV2 = [
  {
    tenant: {
      id: '4',
      name: 'swtchhq',
      displayName: 'SWTCH HQ',
      combineName: 'SWTCH HQ (swtchhq)',
    },
    listings: [
      {
        id: 5,
        title: 'DP01',
        connectivity: false,
        lastConnectivityAt: moment().startOf('hour').format(),
        kind: 'destination',
        price: mockPrice[6],
        loitering: mockLoiterings[4],
        permission: {
          accessType: 'public',
          hidden: false,
          accessCode: 123456,
        },
        tags: mockListingTaggingResp,
      },
      {
        id: 6,
        title: 'DP02',
        connectivity: false,
        lastConnectivityAt: moment().startOf('day').format(),
        kind: 'reservation',
        charger: {
          id: 6,
          chargePointSerialNumber: '1EC1-1-1834-1312',
          chargePointModel: 'EX-1193-1A',
          chargePointVendor: 'PHIHONG',
          online: false,
          chargingType: 'Level 2',
          chargerType: 'single-port',
          serviceStatus: 'in_maintenance' as ServiceStatus,
          firmwareVersion: 'V1.02.21 (BBOX: V00.04.30.00)',
          lastConnectedAt: moment().startOf('week').format(),
          lastDisconnectedAt: '2024-05-15T13:48:51.000Z',
        },
        connector: {
          id: 105,
          connectorId: 1,
          serviceStatus: connectorServiceStatus[0],
          ocppStatus: connectorStatus[0],
          connectorType: 'J1772',
          lastStatusChangedAt: moment().startOf('hour').format(),
        },
        price: mockPrice[3],
        loitering: mockLoiterings[0],
        serviceStatusChangedSince: '2024-08-22T11:11:30.123Z',
        permission: {
          accessType: 'private',
          hidden: true,
          accessCode: 987654,
        },
        tags: mockTenantTaggingResp,
      },
      {
        id: 2,
        title: 'FR04',
        connectivity: false,
        kind: 'reservation',
        charger: {
          id: 5,
          chargePointSerialNumber: '1EC1-1-1834-00000',
          chargePointModel: 'EX-1193-1A33',
          chargePointVendor: 'Delta',
          online: true,
          chargingType: 'Level 2',
          chargerType: 'single-port',
          serviceStatus: 'in_maintenance',
          firmwareVersion: 'V1.02.21 (BBOX: V00.04.30.00)',
          lastConnectedAt: moment().startOf('week').format(),
          lastDisconnectedAt: moment().startOf('month').format(),
        },
        connector: {
          id: 103,
          connectorId: 1,
          serviceStatus: 'under_repair' as ConnectorServiceStatus,
          ocppStatus: 'SuspendedEVSE' as ConnectorStatus,
          connectorType: 'J1772',
          lastStatusChangedAt: moment().startOf('hour').format(),
        },
        price: mockPrice[2],
        loitering: mockLoiterings[1],
        serviceStatusChangedSince: '2024-08-23T10:20:30.123Z',
        permission: {
          accessType: 'private',
          hidden: true,
          accessCode: 666666,
        },
        tags: mockTenantTaggingResp,
      },
    ],
  },
  {
    tenant: {
      id: '2',
      name: '8-queen-elizabeth-blvd-Toronto-ON',
      displayName: 'SWTCH HQ 2',
      combineName: 'SWTCH HQ 2 (8-queen-elizabeth-blvd-Toronto-ON)',
    },
    listings: [
      {
        id: 3,
        title: 'FR02',
        connectivity: true,
        lastConnectivityAt: moment().startOf('day').format(),
        kind: 'destination',
        charger: {
          id: 7,
          chargePointSerialNumber: '1EC1-1-1834-00060',
          chargePointModel: 'EX-1762-5SW1',
          chargePointVendor: 'EV-BOX',
          online: false,
          chargingType: 'DC',
          chargerType: 'single-port',
          serviceStatus: 'activating',
          firmwareVersion: 'V1.00.90SWTCHr2 (BBOX: V1.33)',
          lastConnectedAt: moment().startOf('week').format(),
          lastDisconnectedAt: moment().startOf('hour').format(),
        },
        connector: {
          id: 104,
          connectorId: 1,
          serviceStatus: 'decommissioned' as ConnectorServiceStatus,
          ocppStatus: 'Faulted',
          connectorType: 'CCS',
          lastStatusChangedAt: moment().startOf('week').format(),
        },
        price: mockPrice[3],
        loitering: mockLoiterings[2],
        permission: {
          accessType: 'public',
          hidden: false,
          accessCode: 123321,
        },
        tags: mockListingTaggingResp,
      },
      {
        id: 4,
        title: 'FR03',
        connectivity: true,
        lastConnectivityAt: moment().startOf('week').format(),
        kind: 'destination',
        charger: {
          id: 8,
          chargePointSerialNumber: '1EC1-1-1834-00043',
          chargePointModel: 'EX-1762-5SW2',
          chargePointVendor: 'LITE-ON',
          online: true,
          chargingType: 'DC',
          chargerType: 'single-port',
          serviceStatus: 'under_repair' as ServiceStatus,
          firmwareVersion: '	P0134B0125v7_656.190711',
          lastConnectedAt: moment().startOf('week').format(),
          lastDisconnectedAt: moment().startOf('month').format(),
        },
        connector: {
          id: 104,
          connectorId: 1,
          serviceStatus: 'property_activating' as ConnectorServiceStatus,
          ocppStatus: 'Available',
          connectorType: 'CHAdeMO',
          lastStatusChangedAt: moment().startOf('minute').format(),
        },
        price: mockPrice[1],
        loitering: mockLoiterings[3],
        permission: {
          accessType: 'private',
          hidden: false,
          accessCode: 123123,
        },
        tags: mockListingTaggingResp,
      },
      {
        // id: 1,
        // title: 'TV01',
        // connectivity: true,
        // kind: 'reservation',
        charger: {
          id: 9,
          chargePointSerialNumber: '1EC1-1-1834-00043',
          chargePointModel: 'EX-1762-5SW2',
          chargePointVendor: 'EV-BOX',
          online: true,
          chargingType: 'DC',
          chargerType: 'single-port',
          serviceStatus: 'in_maintenance' as ServiceStatus,
          firmwareVersion: '	P0134B0125v7_656.190711',
          lastConnectedAt: moment().startOf('minute').format(),
          lastDisconnectedAt: moment().startOf('month').format(),
        },
        connector: {
          id: 105,
          connectorId: 1,
          // serviceStatus: 'decommissioned' as ConnectorServiceStatus,
          ocppStatus: 'Unavailable',
          connectorType: 'CCS',
          lastStatusChangedAt: moment().startOf('hour').format(),
        },
        tags: mockListingTaggingResp,
      },
    ],
  },
] as ChargersPerTenant[]

const mockChargersUptimeForTenant = [
  {
    id: 1,
    chargePointSerialNumber: '1EC0-1-1823-00061',
    swtchCare: 'No',
    online: true,
    connector: { id: 1, connectorId: 1, serviceStatus: 'commissioned' },
    listing: { id: 1, title: 'FR02', initialActivationTime: null },
    uptimePercentage: 99.9,
    utilizationRate: 0.1,
    initialActivationDate: moment().format(),
  },
  {
    id: 2,
    chargePointSerialNumber: '1EC0-1-1823-00062',
    swtchCare: 'Yes',
    online: false,
    connector: { id: 2, connectorId: 2, serviceStatus: 'decommissioned' },
    listing: { id: 2, title: 'FR03', initialActivationTime: moment().subtract(6, 'day').format() },
    uptimePercentage: 90.1,
    utilizationRate: 0.9,
  },
] as ChargerUptime[]

export const mockChargersUptimeResponse = (
  tenantId: number,
  page: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
) => {
  let filteredChargersUptime = mockChargersUptimeForTenant

  return {
    data: filteredChargersUptime,
    pagination: {
      currentPage: page,
      totalEntries: filteredChargersUptime.length,
      perPage: 20,
    },
  } as ChargersUptimeForTenantResponse
}

export const mockChargersPerTenantResponse = (filters: ChargersPerTenantFilter) => {
  log('fetching chargers mock', { filters })
  let filteredChargers = mockChargersV2

  if (filters.connectivityStatus === 'online') {
    filteredChargers = filteredChargers.filter((tenantData) =>
      tenantData.listings.some((listing) => listing.connectivity === true),
    )
  }

  if (filters.connectivityStatus === 'offline') {
    filteredChargers = filteredChargers.filter((tenantData) =>
      tenantData.listings.some((listing) => listing.connectivity === false),
    )
  }

  // Filter by listings selected
  if (filters.listings && filters.listings.length > 0) {
    const listingIds = filters.listings.map((listing) => listing.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter((listing) => listingIds.includes(listing.id)),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by chargers
  if (filters.chargers && filters.chargers.length > 0) {
    const chargerIds = filters.chargers.map((charger) => charger.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter((listing) => listing.charger && chargerIds.includes(listing.charger.id)),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by tenants
  if (filters.tenants && filters.tenants.length > 0) {
    const tenantIds = filters.tenants.map((tenant) => tenant.id)
    filteredChargers = filteredChargers.filter((tenantData) => tenantIds.includes(tenantData.tenant.id))
  }

  // Filter by vendors
  if (filters.vendors && filters.vendors.length > 0) {
    const vendorNames = filters.vendors
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) => listing.charger && vendorNames.includes(listing.charger.chargePointVendor),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by service statuses
  if (filters.serviceStatuses && filters.serviceStatuses.length > 0) {
    const serviceStatuses = filters.serviceStatuses
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) =>
            listing.connector &&
            listing.connector.serviceStatus !== undefined &&
            serviceStatuses.includes(listing.connector.serviceStatus),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  // Filter by OCPP statuses
  if (filters.ocppStatuses && filters.ocppStatuses.length > 0) {
    const ocppStatuses = filters.ocppStatuses
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) =>
            listing.connector &&
            listing.connector.ocppStatus !== undefined &&
            ocppStatuses.includes(listing.connector.ocppStatus),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }
  // Filter by taggings
  if (filters.taggings && filters.taggings.length > 0) {
    const taggingIds = filters.taggings.map((tagging) => tagging.id)
    filteredChargers = filteredChargers
      .map((tenantData) => ({
        ...tenantData,
        listings: tenantData.listings.filter(
          (listing) => listing.tags && listing.tags && listing.tags.some((tag) => taggingIds.includes(tag.id)),
        ),
      }))
      .filter((tenantData) => tenantData.listings.length > 0)
  }

  return {
    data: filteredChargers,
    pagination: {
      currentPage: filters.page,
      totalEntries: filteredChargers
        .map((tenantData) => tenantData.listings.length)
        .reduce((acc, curr) => acc + curr, 0),
      perPage: 20,
    },
    filters: { vendors: mockVendors },
  } as ChargersResponse
}

export const mockChargersListingsResponse = (tenantId: number) => {
  return {
    data: mockChargersListings,
    pagination: {
      currentPage: 1,
      totalEntries: 5,
      perPage: 20,
    },
  } as ChargersListingsResponse
}

export const mockChargersListingsLoadMgmtResponse = (tenantId: number) => {
  return {
    data: mockChargersListingsLoadMgmt,
    pagination: {
      currentPage: 1,
      totalEntries: 5,
      perPage: 20,
    },
  } as ChargersListingsLoadMgmtResponse
}
