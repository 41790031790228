import { useHistory } from 'react-router-dom'

import { Links } from 'components/routes/paths'
import { addQueryString } from 'helpers/url'

import { MasterAccountRef } from 'models/master-account'
import { TenantRef } from 'models/tenant'

interface NavigateToChargersParams {
  ocppStatus?: string
  serviceStatus?: string
  connectivityStatus?: string
}

export const useNavigateToChargers = (initialMasterAccount?: MasterAccountRef, initialTenants?: TenantRef[]) => {
  const history = useHistory()

  const navigateToChargers = ({ ocppStatus, serviceStatus, connectivityStatus }: NavigateToChargersParams) => {
    const queryParams: { [key: string]: string | number | string[] } = {}

    // Add status parameters
    if (ocppStatus) queryParams['ocppStatus'] = ocppStatus
    if (serviceStatus) queryParams['serviceStatus'] = serviceStatus
    if (connectivityStatus) queryParams['connectivityStatus'] = connectivityStatus

    // Add MasterAccountRef as a JSON object
    if (initialMasterAccount) {
      queryParams['masterAccount'] = encodeURIComponent(JSON.stringify(initialMasterAccount))
    }

    // Add TenantRef array as a JSON string
    if (initialTenants && initialTenants.length > 0) {
      queryParams['tenants'] = encodeURIComponent(JSON.stringify(initialTenants))
    }

    // Navigate to the chargers page with the query string
    history.push(addQueryString(Links.chargers(), queryParams))
  }

  return { navigateToChargers }
}
